import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from 'src/app/components/components.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NouisliderModule } from 'ng2-nouislider';
import { JwBootstrapSwitchNg2Module } from '@servoy/jw-bootstrap-switch-ng2';
import { AngularMultiSelectModule } from 'ngb-dropdown';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from 'src/app/interceptors/auth.interceptor';
import { ServerErrorInterceptor } from 'src/app/interceptors/server-error.interceptor';
import { HttpClientModule } from '@angular/common/http';
import { NgOtpInputModule } from 'ng-otp-input';
import { ClipboardModule } from 'ngx-clipboard';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DatePipe } from '@angular/common';
import { SHARED_PIPES } from '../pipes';
import { SHARED_DIRECTIVES } from '../directives';
import { CarouselModule } from 'primeng/carousel';
import { SHARED_COMPONENTS } from '../components';
import { BlockUIModule } from 'primeng/blockui';
import { QButtonComponent } from 'src/app/components/q-button/q-button.component';

const MODULES = [
  CommonModule, RouterModule, FormsModule, ReactiveFormsModule, ComponentsModule, NgbModule,
  NouisliderModule, JwBootstrapSwitchNg2Module, AngularMultiSelectModule, HttpClientModule, NgOtpInputModule,
  ClipboardModule, InfiniteScrollModule, CarouselModule, BlockUIModule,QButtonComponent
]

@NgModule({
  imports: [...MODULES],
  declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, ...SHARED_PIPES],
  exports: [...MODULES, ...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, ...SHARED_PIPES],
  providers: [
    DatePipe,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true}
  ]
})
export class SharedModule {}
