<div class="page-header non-transparent-header vpb-6 convo-info-wrapper">
  <div class="convo-info {{isMobile? 'vmy-3':'vmt-5 vmb-3'}}">
    <div class="text-center vmb-6 position-relative">
      <img *ngIf="!isMobile" class="position-absolute top-0 end-0 -mt-2" width="169px" src="assets/img/signup/styling.png" alt="">
      <div class="title home-heading m-0" *ngIf="!isMobile">Sign Up Now</div>
      <p class="title fs-4 text-bold home-description vpt-3 ">Take our Style Quiz to Get Matched with Products You’ll Love</p>
      <p class="title home-description text-muted pt-2 vpb-2"> First, pick the categories you’d like personal product recommendations from:</p>
      <i style="color:#8862a2">Already a Qatch member? Login <a style="color:#8862a2" routerLink="../signin">here!</a></i>
    </div>
    <p-blockUI *ngIf="loading" [blocked]="true">
      <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
         <span class="sr-only">Loading...</span>
      </div>
    </p-blockUI>
    <q-convo class="d-block mx-auto" [loading]="convoCardsLoading" [convoCards]="convoCards" (onUpdate)="onUpdateConvoCards($event)" [isMobile]="isMobile"></q-convo>
    <form class="convo-form mx-auto rounded-inputs-form vmt-9" [formGroup]="convoForm"
      autocomplete="off" autocapitalize="off">
      <div class="row justify-content-center vmt-3 px-4 px-md-0 " *ngIf="convoForm">
        <div class="col-12 col-md-3 mb-3">
          <div class="form-row row">
            <div class="col-md-12">
              <div class="form-group mb-0">
                <div class="input-group form-group-no-border border-custom">
                  <input type="text" class="form-control" placeholder="Enter name" formControlName="name">
                </div>
              </div>
              <uikit-error [submitted]="isSubmittedOnce" [controls]="convoControls" name="name"
                placeholder="Name"></uikit-error>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3 mb-3">
          <div class="input-group form-group-no-border bg-default shadow border-custom">
            <span class="input-suffix">{{countryCode}}</span>
            <input type="text" class="form-control box-shadow-none" appPhoneMask formControlName="phone_number"
              placeholder="(xxx)xxx-xxxx">
          </div>
          <uikit-error [submitted]="isSubmittedOnce" [controls]="convoControls" name="phone_number"
            placeholder="Phone number">
          </uikit-error>
          <div class="vmt-1">
            <small>This service is currently only available in US & Canada</small>
          </div>
        </div>
        <div class="d-flex col-12 col-md-3 mb-4">
          <div class="form-check" *ngIf="isMobile">
            <label class="form-check-label">
              <input class="form-check-input" type="checkbox" formControlName="agree_to_terms" />
              <span class="form-check-sign"> </span>
            </label>
          </div>
          <bSwitch *ngIf="!isMobile" [switch-on-color]="'danger'" [switch-off-color]="'danger'" [switch-off-text]="'No'"
            [switch-on-text]="'Yes'" formControlName="agree_to_terms" required style="height: fit-content;">
          </bSwitch>
          <div class="vml-1">
            <div class="term_and_conditions" (click)="toggleTerms()">By signing up, you agree to our Terms of Service.
              By selecting “I agree,” you are opting into Qatch’s text service. Qatch may receive commission from
              products purchased through links sent via text. To take a break, just text us “PAUSE” and to cancel at any
              time, text us “STOP.” Message and Data rates may apply. Read our Terms here :
              <a [routerLink]="['/terms']">https://www.joinqatch.com/terms</a>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-5 d-flex justify-content-center">
          <q-button title="SIGN UP NOW" type="arrowButton" [disabled]="loading || !convoForm.valid"
            buttonStyleClass="btn-purple" iconStyleClass="btn-dark-purple" [loading]="loading"
            (onBtnClick)="validatePhoneNumber();">
          </q-button>
        </div>
        <p class="title text-bold home-description mb-0 vpt-3 text-center">Tell Us About Your style On The Next Page 👉 </p>
      </div>
    </form>
  </div>
</div>