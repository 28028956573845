import { Component, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  GuidedTourModule,
  GuidedTourService,
  GuidedTour,
  Orientation,
  TourStep
} from 'ngx-guided-tour';
import { ButtonModule } from 'primeng/button';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AppService } from 'src/app/shared/services/app.service';
import { Subscription } from 'rxjs';
import { DeviceDetector } from 'projects/ui-kit/src/lib/services/device-detector.service';

export interface ITourStep extends TourStep {
  nextButtonLabel?: string,
  loaderDelay?: number,
  onInit?: Function
}

export interface IGuidedTour extends GuidedTour {
  steps: ITourStep[]
}

@Component({
  selector: 'q-guided-tour',
  templateUrl: './q-guided-tour.component.html',
  styleUrls: ['./q-guided-tour.component.scss'],
  standalone: true,
  imports: [CommonModule, GuidedTourModule, ButtonModule],
  providers: [GuidedTourService]
})
export class QGuidedTourComponent extends DeviceDetector {
  _defaultGuidedTour: IGuidedTour = {
    tourId: 'qatch-website-guided-tour',
    steps: [
      {
        content: `
          <div class="text-md text-center">
            <strong>Here's a quick tutorial on how to use the site</strong>
          </div>
        `,
        nextButtonLabel: `LET'S GO`,
        loaderDelay: 1400
      },
      {
        selector: '.shop_new_menu_option',
        content: `<div class="text-sm text-center">
          The <span class="text-white">SHOP NEW</span> page contains hundreds of trending items and Stylist-created collections everyday
        </div>
        <div class="text-sm mt-4 text-center">
          React with a 🖤 or 👎🏾 by hovering over the image: <br> click to shop!
        </div>
        `,
        orientation: Orientation.BottomRight,
        nextButtonLabel: `HOVER OVER AN ITEM`
      },
      {
        selector: '.collections_login_product_click:first-child .collections_login_product_love_click',
        content: `
        <div class="text-sm text-center">
         Every item  🖤  will be saved in your MY FAVE'S page
        </div>
        <div class="text-md mt-4 text-center">
        <strong>Now head over to My Fave's in the menu bar</strong>
        </div>
        `,
        scrollAdjustment: 250,
        nextButtonLabel: `Next`,
        orientation: Orientation.Bottom
      },
      {
        selector: '.favorites_menu_option',
        content: `
        <div class="text-md text-center"><strong>This is your corner of the internet.</strong></div>
        <div class="text-sm text-center my-4">All of your recommendations will appear here as well as items you 💜 while browsing Collections.</div>
        <div class="text-md text-center"><strong>Your Stylist is working on your outfits - rate some products while you wait!</strong></div>
        `,
        orientation: Orientation.Bottom,
        nextButtonLabel: `Done`
      }
    ],
    preventBackdropFromAdvancing: true
  };
  guidedTour: IGuidedTour;
  timeoutRef: any;
  activeStep = 0;
  guideTourRef: Subscription;
  constructor(
    private guidedTourService: GuidedTourService,
    private appService: AppService,
    private authService: AuthService,
  ) {
    super();
    this.device.subscribe(() => {
      this.onResizeScreen();
    })
  }

  onResizeScreen() {
    this.guidedTour = JSON.parse(JSON.stringify(this._defaultGuidedTour));
    if(this.isMobile) {
      const config = {selector: null, orientation: Orientation.Center};
      this.guidedTour.steps[1] = {
        ...this.guidedTour.steps[1], 
        ...config, content: `<div class="text-sm text-center">
         The <span class="text-white">SHOP NEW</span> page contains 100's of trending items and Stylist-created collections,updated daily. Rate products with a 🖤 or 👎
        </div>`,
        nextButtonLabel: `RATE AN ITEM`
      };
      this.guidedTour.steps[3] = {...this.guidedTour.steps[3], ...config};
    }
    this.guidedTour.steps[1] = {
      ...this.guidedTour.steps[1],
      onInit: () => {
        const firstProduct = document.querySelector('.collections_login_product_click:first-child .qatch-img-overlay');
        firstProduct.className = firstProduct.className + ' onhover ';
      }
    };
    this.guidedTour.steps[3] = {
        ...this.guidedTour.steps[3], 
        onInit: () => {
        this.appService.navigateWithPreserve('internal/my-qatch');
      }
    };
    if(this.guideTourRef) {
      this.guideTourRef.unsubscribe();
    }
    this.getData();
  }

  getData() {
    this.guidedTour.steps = this.guidedTour.steps.map((step, index) => {
      step.content = `
          ${step.content}
          <div class="p-button btn custom-skip-tour">×</div>
          ${step.nextButtonLabel ? `
            <div class="custom-next-step d-flex mt-4 mx-auto p-button btn btn-white p-button-rounded vpx-6 position-relative">
              ${step.nextButtonLabel}
              <span class="spinner-border spinner-border-sm"></span>
            </div>`
          : ''
        }
        `;
      step.action = this.bindEvents.bind(this, index);
      return step;
    });
    this.guideTourRef = this.authService.guideTour$.subscribe((delay: number) => {
      if (this.timeoutRef) {
        window.clearTimeout(this.timeoutRef);
        this.timeoutRef = null;
      }
      if (delay >= 0) {
        this.timeoutRef = setTimeout(() => {
          this.appService.navigateWithPreserve('discover/collections');
          this.guidedTourService.startTour(this.guidedTour);
        }, delay);
      }
    });
  }

  bindEvents(index) {
    this.activeStep = index;
    const step = this.guidedTour.steps[index];
    if (step && step.onInit) {
      this.guidedTour.steps[index].onInit();
    }
    setTimeout(() => {
      const nextBtn = document.querySelector('.custom-next-step');
      if (nextBtn) {
        nextBtn.addEventListener('click', () => {
          this.guidedTourService.nextStep();
        });
        nextBtn.querySelector('.spinner-border')?.remove();
      }
      const skipTourBtn = document.querySelector('.custom-skip-tour');
      if (skipTourBtn) {
        skipTourBtn.addEventListener('click', () => {
          this.guidedTourService.skipTour();
          const product = document.querySelector('.collections_login_product_click:first-child .qatch-img-overlay.onhover');
          if (product) {
            product.className = product.className.replace('onhover', '');
          }
        });
      }
    }, step.loaderDelay || 10);
  }

}
